<template>
  <div class="zchjs">
    <div class="header">
      <div class="h1">产业园区</div>
      <div class="h2">助力数字化产业园区发展</div>
    </div>
    <div class="content">
      <el-page-header style="font-size: 14px" @back="goBack">
        <div style="font-size: 14px" slot="content">企业详情</div>
      </el-page-header>
      <div class="compxq">
        <div  class="flex">
          <div>
            <el-avatar  shape="square" :size="60" src="https://empty">
              <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
            </el-avatar>
          </div>
          <div class="comptitle">
            <div class="h1">{{datas.enterpriseName}}</div>
            <div>{{datas.enterpriseCode}}</div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div>
        <div class="datitle">企业信息</div>
        <div class="compdesc">
          <el-descriptions class="margin-top"  :column="2" >
            <el-descriptions-item label="法定代表人">{{datas.legalPerson}}</el-descriptions-item>
            <el-descriptions-item label="联系人"></el-descriptions-item>
            <el-descriptions-item label="注册资本">{{datas.registeredCapital}}</el-descriptions-item>
            <el-descriptions-item label="电话">{{datas.phone}}</el-descriptions-item>
            <el-descriptions-item label="统一社会信用代码">{{datas.enterpriseCode}}</el-descriptions-item>
            <el-descriptions-item label="企业类型">{{datas.enterpriseNatureName}}</el-descriptions-item>
            <el-descriptions-item label="成立时间">{{datas.foundedTime}}</el-descriptions-item>
            <el-descriptions-item label="所在区域">{{datas.provinceName}}{{datas.cityName}}{{datas.countyName}}</el-descriptions-item>
            <el-descriptions-item label="地址" :span="2">{{datas.address}}</el-descriptions-item>
            <el-descriptions-item label="经营范围" :span="2">{{datas.businessScope}}</el-descriptions-item>
            <el-descriptions-item label="公司简介" :span="2">{{datas.other}}</el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { fiveyears } from '../../utils/index'
import {policyindex, policypage} from "../../request/moudle/zcfw";
import {enterprisedetail, parklist} from "../../request/moudle/park";
import fujian from "../common/fujian.vue";
export default {
  name: "zchj",
  components: {fujian},
  data(){
    return {
      datas:''
    }
  },
  created() {
    this.loadxq()
  },
  methods:{
    goBack() {
     this.$router.go(-1)
    },
    loadxq(){
      this.$api.park.enterprisedetail({
        id:this.$route.query.id
      }).then((res)=>{
        this.datas=res.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-descriptions-item__label{
  min-width: 120px;
}
.zchjs{
  background: #F5F5F5;
}
.datitle{
  font-weight: bolder;
}
.header{
  height: 220px;
  background: url("../../assets/banner/yqgl.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.content{
  width: 1200px;
  margin: 0px auto;
  background: #fff;
  padding: 20px;
  /deep/ .el-col{
    padding: 15px ;
  }
  .compdesc{
    font-size: #3E3E3E;
    text-align: left;
    margin-top: 10px;
  }
  .compxq{
    margin-top: 25px;
    .comptitle{
      margin-left: 20px;
    }

    .h1{
      font-weight: bolder;
      margin-bottom: 10px;
    }
  }
}
</style>